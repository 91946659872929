




















































import { Component, Vue } from 'vue-property-decorator'
@Component
export default class ChildrenPrivacy extends Vue {
   statusHeight:string  = 120 + 'rpx'
}
